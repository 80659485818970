<template>
    <nav class="navbar">
        <div class="collapse navbar-collapse" id="navbar-collapse">
            <div class="header-menu check-status" v-if="isMenuitemsLoaded && showHeaderMenu" id="headerMenuList">
                <ul class="list">
                    <li v-for="item in menuitems"
                        v-tt="item.vTTValue"
                        :key="item.text"
                        :id="item.text"
                        :ref="'menu-'+item.text"
                        :class="['top-level-item', menuIsActive(item) ? 'active' : '']"
                        v-if="hasPermission(item.permission)"
                        @mouseover="setSubmenuTop(item.text)">

                        <a @click="onClickMenu($event, item)"
                           :class="[item.submenu.length > 0 ? 'menu-toggle' : '', item.toggled ? 'toggled' : '']"
                           :content="item.text">
                            <i :class="`${item.icon}`"></i>
                            <span class="item-text">{{ item.text }}</span>
                        </a>

                        <submenu :ref="'submenu-'+item.text"
                                 :class="item.toggled ? 'd-block' : ''"
                                 v-if="item.submenu.length > 0"
                                 :parent-text="item.text"
                                 :menuitems="item.submenu" />
                    </li>
                </ul>
            </div>
            <div class="header-functions">
                <ul class="nav navbar-nav">
                    <li>
                        <router-link v-tt="'header-profile'" to="/account">
                            <!-- <img src="https://www.gravatar.com/avatar/e7c88187f32c08e7ce5296a7a297515c.jpg?s=80&amp;d=mm&amp;r=g"
                             class="img-circle"
                             alt="User Image" />-->
                            <i class="dx-icon dx-icon-user"></i>
                            <span class="header-profile-username">{{ username }}</span>
                        </router-link>
                    </li>
                    <li class="header-langchooser">
                        <DxDropDownButton id="langSet"
                                          v-tt="'header-langchooser'"
                                          :items="languages"
                                          key-expr="id"
                                          class="localDropdown"
                                          title-expr="text"
                                          display-expr="text"
                                          :use-select-mode="true"
                                          :selected-item-key="1"
                                          @selection-changed="changeLocal">
                            <DxDropDownOptions :width="110" />
                        </DxDropDownButton>
                    </li>
                    <li>
                        <div v-tt="'header-colorchooser'">
                            <img src="/img/icons/theme_icon.svg" style="height: 23px;vertical-align:middle;padding-bottom:1px;" />
                        </div>
                    </li>
                    <li class="bug-report">
                        <div v-tt="'header-errorreport'" :class="['bug-report', bugReportSpinnerActive ? 'spinner' : '']">
                            <a @click="onClickBugReport($event)">
                                <i class="iconset bug_report"></i>
                            </a>
                        </div>
                    </li>
                    <li>
                        <a v-tt="'header-logout'" @click="logoutRequest">
                            <i class="iconset logout_log-out"
                               data-toggle="tooltip"
                               data-placement="bottom"
                               data-original-title="Logout"></i>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
</template>

<script>

import DxDropDownButton, { DxDropDownOptions, DxPosition } from 'devextreme-vue/drop-down-button';
import Submenu from "./Submenu";
import { permissionCheckerMixin } from "@appfrm/core/mixins/permissionCheckerMixin";

export default {
    name: "Header",
    components: {
        DxDropDownButton,
        DxDropDownOptions,
        DxPosition,
        Submenu
    },
    props: ["username", "user-roles"],
    mixins: [permissionCheckerMixin],
    data() {
        return {
            languages: [
                { id: 1, value: "hu", text: 'Magyar', icon: "/img/flags/hu.svg" },
                { id: 2, value: "en", text: 'English', icon: "/img/flags/gb.svg" },
                { id: 3, value: "de", text: 'Deutsch', icon: "/img/flags/de.svg" },
                { id: 4, value: "ru", text: 'Russian', icon: "/img/flags/ru.svg" },
                { id: 5, value: "ua", text: 'Ukrainian', icon: "/img/flags/ua.svg" },
                { id: 6, value: "sr", text: 'Serbian', icon: "/img/flags/sr.svg" }
            ],
            bugReportSpinnerActive: false,
            menuitems: {
                default: [],
                type: Array
            },
            isMenuitemsLoaded: false,
            showHeaderMenu: false,
            headerMenuSidebarState: 'ls-closed-and-show-header-menu',
            itemWidthGap: 5
        }
    },
    mounted() {
        this.$root.$on('get_diagnostics_data', (callbackFunction) => {
            var jsonData = this._data;
            callbackFunction(this.$options._componentTag, JSON.stringify(jsonData));
        });
        this.$root.$on('activate_bug_spinner', () => {
            this.bugReportSpinnerActive = true;
        });
        this.$root.$on('deactivate_bug_spinner', () => {
            this.bugReportSpinnerActive = false;
        });
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.onWindowResize);
    },
    created() {
        this.$root.$on('menuitems_created', (data) => {
            this.menuitems = data.filter(item => item.showInHeader == true);
            this.isMenuitemsLoaded = true;
        });

        this.$root.$on('sidebar_state_changed', (data) => {
            this.showHeaderMenu = data == this.headerMenuSidebarState;
            this.$nextTick(function () {
                this.updateHeaderMenuStyles();
            })
        });

        window.addEventListener('resize', this.onWindowResize);
    },
    methods: {
        logoutRequest: function () {
            this.$emit('logout');
        },
        changeLocal(e) {
            locale(e.itemData.value);
        },
        onClickBugReport(e) {
            if (!this.bugReportSpinnerActive) {
                this.$root.$emit("show_bugreport_popup");
            }
        },
        menuIsActive(item) {
            return this.$route.path.startsWith(item.href) || item.activeHrefs.indexOf(this.$route.path) != -1;
        },
        setSubmenuTop: function (menu) {
            let top = this.$refs['menu-' + menu][0].getBoundingClientRect().top + 50;

            if (this.$refs.hasOwnProperty('submenu-' + menu)) {
                let $ref = this.$refs['submenu-' + menu][0];
                $ref.$el.style.top = top + 'px';
            }
        },
        onClickMenu(event, item) {

            if (item.submenu.length == 0) {
                this.$router.push(item.href);
                return;
            }

            if (!document.querySelector('body').classList.contains('ls-closed'))
                item.toggled = !item.toggled;

            // Main menu background color
            let submenuIsActive = item.submenu.some(item => {
                return this.menuIsActive(item);
            });

            let menu = document.getElementById(item.text);
            if (submenuIsActive && menu.firstChild.classList.contains('toggled')) {
                menu.classList.add('active');
            } else {
                menu.classList.remove('active');
            }
        },
        updateHeaderMenuStyles() {
            let headerMenu = document.getElementById('headerMenuList');
            if (headerMenu) {
                headerMenu.classList.remove('icon-only');
                headerMenu.classList.add('check-status');
                let menuitems = document.querySelectorAll(".top-level-item");
                let iconOnly = false;
                if (menuitems.length > 0) {
                    let itemsWidth = 0;
                    menuitems.forEach((item) => {
                        itemsWidth += item.clientWidth + this.itemWidthGap;
                    });
                    iconOnly = headerMenu.clientWidth < itemsWidth;
                }

                if (iconOnly) {
                    headerMenu.classList.add('icon-only');
                }

                headerMenu.classList.remove('check-status');
            }
        },
        onWindowResize() {
            this.updateHeaderMenuStyles();
        }
    }
};
</script>

<style scoped lang="scss">
.nav > li > a {
    text-decoration: none;
}
.nav > li > a > .img-circle {
    width: 100%;
    max-width: 20px;
    height: auto;
    border-radius: 50%;
    border: 0;
    position: relative;
    bottom: 1px;
    vertical-align:middle
}
.nav > li > a .dx-icon {
    font-size: 24px;
    vertical-align: middle;
}
.nav > li > a > span {
    margin-left: 5px;
    line-height: 24px;
    font-size: 14px;
}
::v-deep .localDropdown {
    .dx-button-content {
        line-height: 21px;
        padding: 12px 18px 7px;
    }
    .dx-button-text {
        color: #ffffff;
    }
    .dx-button-mode-outlined {
        border: 0;
    }
    .dx-icon {
        vertical-align: bottom;
        height: 20px !important;
        width: 28px;
        margin-right: 0 !important;
    }
    .dx-icon.dx-icon-right{
        width: 0;
    }
    .dx-overlay-content {
        left: 10px;
    }
    .dx-button-text {
        display: none;
    }
}
.header-functions ul.navbar-nav li {
    padding-left: 31px;
    line-height: 40px;
}
.ls-closed-and-show-header-menu .header-functions ul.navbar-nav li {
    padding-left: 16px;
}
.bug-report a {
    color: #fff;
}
.bug-report-chooser {
    position: absolute;
    left: -120px;
    top: 40px;
    background-color: #012A4A;
    color: #fff;
}
ul.bug-report-chooser {
    list-style: none;
    padding: 0px;
}
.bug-report-chooser li {
    padding: 0px 20px;
    list-style: none;
    white-space: nowrap;
}
.notificationCount {
    position: relative;
    top: -2px;
    background-color: #ff0000;
    color: #fff;
    font-size: 11px;
    font-weight: bold;
    line-height: 22px;
    padding: 0px 0px;
    -webkit-border-radius: 22px;
    -moz-border-radius: 22px;
    border-radius: 22px;
    display: inline-block;
    width: 22px;
    height: 22px;
    text-align: center;
}
</style>
<style>
.dx-scrollable.dx-scrollview.dx-widget .dx-scrollable-wrapper {
    border: none;
}
.dx-list-item-icon {
    vertical-align: bottom;
    height: 13px !important;
}
.dx-popup-content {
    text-align: left !important;
}
.ls-closed-and-show-header-menu .header-profile-username {
    display: none;
}
.ls-closed-and-show-header-menu .header-functions {
    width: 246px;
}
.ls-closed-and-show-header-menu .header-functions ul.navbar-nav {
    padding-left: 0;
}
.header-langchooser .dx-dropdownbutton-action.dx-buttongroup-last-item.dx-button .dx-button-content {
    padding-left: 3px;
    padding-right: 0;
    padding-top: 10px;
}
</style>
