import Vue from 'vue'
import Vuex from 'vuex'
import axios from "axios";
import VuexPersistence from "vuex-persist";

const vuexPersist = new VuexPersistence({
    key: "my-app",
    storage: window.localStorage,
    modules: ['core'],
});

Vue.use(Vuex);

let healthCheckId = null;

const store = new Vuex.Store({
    plugins: [vuexPersist.plugin],
    state: {
        isLoginError: false,
    },
    getters: {
        isLoginErrorGetter(state) {
            return state.isLoginError;
        },
    },
    actions: {
        startHealthCheck(context) {
            console.log(`startHealthCheck`);
            return new Promise(resolve => {
                context.dispatch('healthCheck').then(() => {
                    resolve();
                });
            });
        },
        healthCheck(context) {
            console.log(`healthCheck`);
            return new Promise(resolve => {
                healthCheckId = setInterval(
                    () => {
                        axios
                            .get(
                                `/api/healthcheck`
                            )
                            .then(() => {
                                clearInterval(healthCheckId);
                                context.commit('serverOnline');
                                resolve();
                            })
                            .catch(() => {
                                context.commit('healthCheck');
                            });
                    }, 5000
                );
            });
        }
    },
    mutations: {
        showLoginError(state) {
            state.isLoginError = true;
        },
    }
});

export { store as default };