import 'core-js/stable';
import 'regenerator-runtime/runtime';
import Vue from 'vue';
import './plugins/axios';
import vuetify from './plugins/vuetify';
import router from './router';
import store from './store';
import './registerServiceWorker';
import dateFilter from '@/filters/date.filter';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import 'devexpress-diagram/dist/dx-diagram.css';
import './scss/common.scss';
import i18n from "./i18n";
import axios from "axios";
import Main from "./Main.vue";
import Core from '@appfrm/core';
import OnlineHelp from '@appfrm/online-help';
import DataContainer from '@appfrm/data-container/index.js';
import DataSourceMap from '@appfrm/data-map';
import DataSourceHiearchy from '@appfrm/data-hiearchy';
import {config} from "@/config";

import enMessages from "devextreme/localization/messages/en.json";
import deMessages from "devextreme/localization/messages/de.json";
import ruMessages from "devextreme/localization/messages/ru.json";
import huMessages from "devextreme/localization/messages/hu.json";
//import roMessages from "devextreme/localization/messages/ro.json";
//import srMessages from "devextreme/localization/messages/sr.json";
import { locale, loadMessages } from "devextreme/localization";
window.locale = locale;

Vue.config.productionTip = false;
Vue.filter('date', dateFilter);
Vue.use(Core, { store, apiName: config.apiName, spaClientId: config.spaClientId });
Vue.use(OnlineHelp, { store });
Vue.use(DataContainer, { store });
Vue.use(DataSourceHiearchy, { store });
Vue.use(DataSourceMap, { store, googleMapKey: 'AIzaSyDf_CnZSAUtFkKL4_4N6niQ61MdAwSP-dg' });

axios.interceptors.request.use(
    config => {
      const accessToken = store.state.core.accessToken;
      if (accessToken) {
        config.headers["Authorization"] = `Bearer ${accessToken}`;
      }

      return config;
    },

    error => {
      return Promise.reject(error);
    }
);

export default new Vue({
    vuetify,
    router,
    store,
    i18n,
    render: (h) => h(Main),
    created: function () {
        loadMessages(enMessages);
        loadMessages(deMessages);
        loadMessages(ruMessages);
        loadMessages(huMessages);
        locale(navigator.language);
    }
}).$mount('#app');
