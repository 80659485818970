<template>
    <v-app :class="!isLoginSuccess ? 'login-container' : null">
        <div v-if="errorMessage" class="errorMessage">{{ errorMessage }}</div>
        <div v-if="!isLoginSuccess" class="login-component">
            <LoginComponent></LoginComponent>
        </div>
        <div v-if="isLoginSuccess" class="d-flex flex-1">
            <navigation
                active-menu="/"
                :user-fullname="user.fullName"
                current-year="2022"
                :app-name="config.appTitle"
                :current-version="systemInfo && systemInfo.versionInfo ? systemInfo.versionInfo.commitVersionText : 'unknown'"
                public-website="localhost"
            />

            <ScreenshotMaker></ScreenshotMaker>
            <v-main>
                <router-view :key="$route.fullPath" />
            </v-main>
            <online-help-window></online-help-window>
            <tooltip-editor-window></tooltip-editor-window>
        </div>

        <loader :load="isLoading" :isFull="isFullLoad"></loader>
    </v-app>
</template>

<script>
import { handleStandardErrorNotification } from "@appfrm/core";
import LoginComponent from "./router/views/Login.vue";
import { AppStatus } from "@appfrm/core/enums/AppStatus";
import Loader from "./components/Loader.vue";
import EventBus from "@appfrm/core/utils/event-bus";
import Navigation from "./components/Navigation";
import ScreenshotMaker from "@appfrm/issue-tracker/components/ScreenshotMaker";
import SystemInfoService from "./services/systemInfo.service";
import { config } from '@/config.js';


let timerId = null;
let minutes = 0;
let seconds = 0;

export default {
    components: {
        LoginComponent,
        Navigation,
        Loader,
        ScreenshotMaker
    },
    data() {
        return {
            clipped: true,
            drawer: true,
            miniVariant: false,
            right: true,
            title: config.appTitle,
            email: '',
            password: '',
            refreshTimer: null,
            hub: null,
            mainWebsocketHubStarted: null,
            logoutTime: null,
            systemInfo: null,
            config: config
        };
    },
    props: {
        load: {
            type: Boolean,
            default: false
        },
        isFull: {
            type: Boolean,
            default: false
        },
    },
    async created() {
        EventBus.$on("START_LOGOUT_TIMER", () => this.startLogoutTimer());
        console.log(`%c ${this.appStatus}`, 'background-color: blue; color: white');
        if (!this.$store.state.core.rememberMe &&
            this.$store.getters.isSessionExpired) {
            this.$store.dispatch('redirectForLogin');
        } else {
            this.$store.dispatch('loginWithRefreshToken').then((r) => {
                this.startLogoutTimer();
            });
        }
    },
    computed: {
        appStatus() {
            return this.$store.state.core.appStatus;
        },
        user() {
            return this.$store.state.core.user;
        },
        isLoading: function () {
            return (
                this.appStatus === AppStatus.Logging ||
                this.appStatus === AppStatus.Init ||
                this.appStatus === AppStatus.AutoLogging ||
                this.appStatus === AppStatus.AutoReLogging
            );
        },
        isFullLoad: function () {
            return (
                this.appStatus === AppStatus.Init ||
                this.appStatus === AppStatus.AutoLogging ||
                this.appStatus === AppStatus.AutoReLogging
            );
        },
        isLoginSuccess: function () {
            return this.appStatus === AppStatus.Success;
        },
        errorMessage: function () {
            return this.$store.state.core.errorMessage;
        },
    },
    methods: {
        logout() {
            this.$store.dispatch('logout');
        },
        startLogoutTimer() {
            if (this.$store.state.core.isTimeout) {
                this.resetIdleTimer();
                timerId = setInterval(this.tickIdle, 1000);

                window.addEventListener("mousedown", this.resetIdleTimer);
                window.addEventListener("keydown", this.resetIdleTimer);
                window.addEventListener("touchstart", this.resetIdleTimer);
                window.addEventListener("resize", this.resetIdleTimer);
            }
        },
        resetIdleTimer() {
            this.logoutTime = 900;
        },
        tickIdle() {
            this.logoutTime -= 1;
            if(this.logoutTime < 0) {
                clearInterval(timerId);
                this.logout();
            }
        },
    },
    async mounted() {
        try {
            this.systemInfo = await SystemInfoService.get();
        } catch (error) {
            handleStandardErrorNotification("Nem sikerült a rendszerinformációk betöltése.", error);
        }
    }
}

</script>

<style lang="scss" scoped>
.main {
    height: 100%;
    display: grid;
    grid-template-rows: 1fr;
    &.error {
        grid-template-rows: 30px 1fr;
    }
}
.errorMessage {
    @import "scss/variables";
    z-index: 10000;
    background-color: $attention-color;
    color: white;
    font-family: "Roboto", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 18px;
    display: flex;
    justify-content: center; /* align horizontal */
    align-items: center; /* align vertical */
    width: 500px;
}
.login-component {
    display: flex;
    flex: 1;
}
.login-container {
    background-image: url("../public/img/background.jpg") !important;
    background-repeat: no-repeat !important;
    background-attachment: fixed !important;
    background-position: right 0 top 0 !important;
    background-size: auto;
    margin: 0 !important;
    padding: 0 !important;
    max-width: 100% !important;
}
@media (min-width: 1921px) {
    .login-container {
        background-size: cover;
    }
}
@media (min-height: 1081px) {
    .login-container {
        background-size: cover;
    }
}
.showError {
    color: red;
}
</style>
