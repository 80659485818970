<template>
    <div>
        <aside id="leftsidebar" class="sidebar">
            <div v-tt="'header-sidebar-toggle'" class="toggle-sidebar-holder" @click="toggleSidebar">
                <a href="javascript:void(0);" class="toggle-sidebar">
                    <i class="iconset menu_menu"></i>
                </a>
            </div>
            <div class="navbarlogo-holder">
                <div class="navbarlogocont text-center">
                    <router-link to="/">
                        <img src="../../public/img/logo.png" alt="" style="max-width:250px; width: 100%" />
                    </router-link>
                </div>
            </div>
            <div class="menu">
                <ul class="list">
                    <li v-for="item in menuitems"
                        :key="item.text"
                        :id="item.text"
                        :title="item.text"
                        :ref="'menu-'+item.text"
                        :class="[menuIsActive(item) ? 'active' : '', item.separator ? 'nav-separator' : '', spinnerActive && item.icon.split(' ').includes('bug_report') ? 'spinner' : '']"
                        v-if="hasPermission(item.permission)"
                        @mouseover="setSubmenuTop(item.text)">

                        <a @click="onClickMenu($event, item)"
                           :class="[item.submenu.length > 0 ? 'menu-toggle' : '', item.toggled ? 'toggled' : '']"
                           :content="item.text">
                            <i :class="`${item.icon}`"></i>
                            <span>{{ item.text }}</span>
                        </a>

                        <submenu :ref="'submenu-'+item.text"
                                 :class="item.toggled ? 'd-block' : ''"
                                 v-if="item.submenu.length > 0"
                                 :parent-text="item.text"
                                 :menuitems="item.submenu" />
                    </li>
                </ul>
            </div>
            <!-- Footer -->
            <!--
    <div class="bug-report">
        <a @click="onClickBugReport($event)">
            <i class="iconset bug_report"></i>
            <span>Hibabejelentés</span>
        </a>
    </div>
            <div class="bug-report" style="bottom: 100px; border: 1px solid #ff0000;">
                <a @click="showTaskPopup()">
                    <i class="iconset bug_report"></i>
                    <span>Hibabejelentés</span>
                </a>
            </div>-->

            <div class="legal">
                <div style="display: flex; justify-content: space-between;">
                    <div class="copyright">
                        &copy; {{ currentYear }} <span>{{ appName }}</span>
                    </div>
                    <div class="version">
                        <span>v</span>{{ currentVersion }}
                    </div>
                </div>
            </div>
            <!-- #Footer -->
            <task-popup ref="task-popup" />
        </aside>
    </div>
</template>

<script>
import Submenu from "./Submenu";
import VueTippy, { TippyComponent } from "vue-tippy";
import Vue from "vue";
import 'tippy.js/dist/tippy.css';
import TaskPopup from "@appfrm/issue-tracker/components/TaskPopup.vue"
import {permissionCheckerMixin} from "@appfrm/core/mixins/permissionCheckerMixin";
import { dataContainerPathFactory, dataContainerTypes, dataTransformation } from "@appfrm/data-container";

Vue.use(VueTippy);
Vue.component("tippy", TippyComponent);

export default {
    name: "Sidebar",
    components: {
        Submenu,
        VueTippy,
        TaskPopup
    },
    mixins: [permissionCheckerMixin],
    props: {
        activeMenu: {
            type: String,
            default: 'false'
        },
        currentYear: {
            type: String,
            default: 2022
        },
        appName: {
            type: String,
            default: ''
        },
        currentVersion: {
            type: String,
            default: '1.00.000'
        },
        userRoles: {
            type: Function,
            default: null
        }
    },
    data() {
        return {
            menuitems: {
                default: [],
                type: Array
            },
            spinnerActive: false,
            sidebarStates: {
                default: 'ls-default',
                closed: 'ls-closed',
                showHeaderMenu: 'ls-closed-and-show-header-menu'
            },
            sidebarStateSequence: [],
            currentSidebarState: 0
        };
    },
    computed: {
        drawingPopup: function () {
            return (this.$refs[this.drawingScreenshotPopupRefName]).instance;
        },
    },
        created() {
            this.menuitems = [
                /*{
                    text: 'Dashboard', icon: 'iconset home_home', href: '/', submenu: []
                },*/
                /*{
                    text: 'E-Hub', icon: 'iconset factory_institution', href: 'javascript:void(0);', toggled: false, submenu: [
                */
                /*    ]
                },*/
                { text: dataContainerTypes.sourceData.menuTitle, icon: dataContainerTypes.sourceData.icon, href: dataContainerPathFactory.getDataContainerDashboardPageUrl(dataContainerTypes.sourceData), submenu: [], activeHrefs: [], showInHeader: true },
                { text: dataContainerTypes.derivedData.menuTitle, icon: dataContainerTypes.derivedData.icon, href: dataContainerPathFactory.getDataContainerDashboardPageUrl(dataContainerTypes.derivedData), submenu: [], activeHrefs: [], showInHeader: true },
                { text: dataContainerTypes.masterData.menuTitle, icon: dataContainerTypes.masterData.icon, href: dataContainerPathFactory.getDataContainerDashboardPageUrl(dataContainerTypes.masterData), submenu: [], activeHrefs: [], showInHeader: true },
                { text: dataContainerTypes.dataService.menuTitle, icon: dataContainerTypes.dataService.icon, href: dataContainerPathFactory.getDataContainerDashboardPageUrl(dataContainerTypes.dataService), submenu: [], activeHrefs: [], showInHeader: true },
                { text: dataTransformation.menuTitle, icon: dataTransformation.icon, href: dataTransformation.path, submenu: [], activeHrefs: [], showInHeader: true },
                {
                    text: 'Adminisztráció', icon: 'iconset administration', href: 'javascript:void(0);', toggled: false, submenu: [
                        { text: 'Saját fiók', icon: 'iconset account_profile', href: '/account', permission: 'core.profile.view', submenu: [], activeHrefs: [] },
                        { text: 'Felhasználók', icon: 'iconset people-icon', href: '/users', permission: 'core.users.view', submenu: [], activeHrefs: [] },
                        { text: 'Szerepkörök', icon: 'iconset terms-and-conditions', href: '/roles', permission: 'core.roles.view', submenu: [], activeHrefs: [] },
                        // { text: 'Server Settings', icon: 'iconset information_information', href: '/server-settings', permission: 'serversettings', submenu: [], activeHrefs: [] },
                        { text: 'Fejlesztési napló', icon: 'iconset system-log', href: '/developmentInfo/changelog', submenu: [], activeHrefs: ['/developmentInfo/bugReports', '/developmentInfo/featureRequests'] }
                    ], activeHrefs: [], showInHeader: true
                },
                /*{
                    text: 'Sales', icon: 'iconset people-icon', href: 'javascript:void(0);', toggled: false, submenu: [
                        { text: 'Leads', icon: '', href: '/leads', submenu: [] },
                        { text: 'Demo Scheduler', icon: '', href: '/scheduler', submenu: [] },
                    ]
                },
                {
                    text: 'Projects', icon: 'iconset chart-line_graphs', href: '/projects', submenu: []
                },*/
                {
                    text: 'Hibabejelentés', icon: 'iconset bug_report', href: 'javascript:void(0);', submenu: [], activeHrefs: []
                },
                {
                    text: 'Logout', icon: 'iconset logout_log-out', href: '/logout', separator: true, submenu: [], activeHrefs: []
                },
            ];
            this.$root.$emit('menuitems_created', this.menuitems);

            this.sidebarStateSequence = [
                this.sidebarStates.default,
                this.sidebarStates.closed,
                this.sidebarStates.showHeaderMenu
            ];

            let body = document.getElementsByTagName('body')[0];
            body.classList.add(this.sidebarStateSequence[this.currentSidebarState]);
        },
    mounted() {
        this.$root.$on('show_bugreport_popup', (callbackFunction) => {
            this.onClickBugReport();
        });
    },
    methods: {
        toggleSidebar() {
            let body = document.getElementsByTagName('body')[0];

            this.currentSidebarState = this.currentSidebarState < (this.sidebarStateSequence.length - 1) ? this.currentSidebarState + 1 : 0;

            this.sidebarStateSequence.forEach((state) => {
                if (this.sidebarStateSequence[this.currentSidebarState] == state) {
                    body.classList.add(state);
                }
                else {
                    body.classList.remove(state);
                }
            });

            this.$root.$emit('sidebar_state_changed', this.sidebarStateSequence[this.currentSidebarState]);

            // Close submenus
            this.menuitems.forEach((item) => {
                item.toggled = false;
            });

            // Main menu background color
            let whichMenuActive = null;
            this.menuitems.forEach(item => {
                let submenuIsActive = item.submenu.some(item => {
                    return this.menuIsActive(item);
                });
                let menuIsActive = this.menuIsActive(item);

                if (submenuIsActive || menuIsActive)
                    whichMenuActive = item.text;
            });

            if (whichMenuActive && (body.classList.contains(this.sidebarStates.closed) || body.classList.contains(this.sidebarStates.showHeaderMenu)) ) {
                document.getElementById(whichMenuActive).classList.add('active');
            }
        },
        menuIsActive(item) {
            return this.$route.path.startsWith(item.href) || item.activeHrefs.indexOf(this.$route.path) != -1;
        },
        showTaskPopup() {
            this.$refs['task-popup'].showTaskPopup();
        },
        setSubmenuTop: function (menu) {
            let top = this.$refs['menu-' + menu][0].getBoundingClientRect().top - 40;

            if (this.$refs.hasOwnProperty('submenu-' + menu)) {
                let $ref = this.$refs['submenu-' + menu][0];
                $ref.$el.style.top = top + 'px';
            }
        },
        onClickMenu(event, item) {

            var icons = item.icon.split(" ");
            if (icons.includes('bug_report')) {
                // A felhasználó épp megnyomta a Hibabejelentés főmenüt.
                this.onClickBugReport(event, item);
                return;
            }

            if (item.submenu.length == 0) {
                if (item.href == '/logout')
                    this.$emit('logout');
                else
                    this.$router.push(item.href);
                return;
            }

            if (!document.querySelector('body').classList.contains('ls-closed'))
                item.toggled = !item.toggled;

            // Main menu background color
            let submenuIsActive = item.submenu.some(item => {
                return this.menuIsActive(item);
            });

            let menu = document.getElementById(item.text);
            if (submenuIsActive && menu.firstChild.classList.contains('toggled')) {
                menu.classList.add('active');
            } else {
                menu.classList.remove('active');
            }
        },
        onScreenshotReturned(dataUrl) {
            ;
        },
        onScreenshotCreated() {
            this.spinnerActive = false;
            this.$root.$emit('deactivate_bug_spinner');
            this.$root.$emit('get_latest_screenshot', this.onScreenshotReturned);
        },
        onClickBugReport(event, item) {
            this.spinnerActive = true;
            this.$root.$emit('activate_bug_spinner');
            this.$root.$emit('make_screenshot', 'app', this.onScreenshotCreated);
        },
    },
};
</script>

<style>
.tippy-content {
    font-family: Roboto;
}
</style>