import Vue from 'vue';
import Router from 'vue-router';
import store from "../store";
import accountService from "@appfrm/core/services/account.service";
import HomePage from "@/router/views/HomePage";
import { issueTrackerRoutes } from "@appfrm/issue-tracker"
import { coreRoutes } from "@appfrm/core/routes"
import { dataContainerRoutes } from "@appfrm/data-container"

Vue.use(Router);

var routes = [
    {
        path: '/',
        name: 'HomePage',
        component: HomePage,
    },
    {
        path: '/server-settings',
        name: 'server-settings',
        component: () => import(/* webpackChunkName: "server-settings" */ './views/ServerSettings.vue'),
    },
    {
        // will match everything
        path: '*',
        redirect: '/'
    }
]

routes = routes.concat(coreRoutes);
routes = routes.concat(issueTrackerRoutes);
routes = routes.concat(dataContainerRoutes);

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: routes,
});

const originalPush = Router.prototype.push;
Router.prototype.push = function push(
    location,
    onComplete,
    onAbort
) {
    if (onComplete || onAbort)
        return originalPush.call(this, location, onComplete, onAbort);
    return originalPush.call(this, location).catch((err) => err);
};

router.beforeEach(async (to, from, next) => {
    to.matched.forEach(record => {
        if (record.meta.permissions) {
            if (!accountService.userHasAllPermissions(record.meta.permissions)) {
                next("/");
            }
        }
    });

    if (store.getters.isSessionExpired && to.path != '/login') {
        next("/login");
        return;
    }

    next();
});

export default router;
