<template>
  <div class="vld-parent">
    <loading
        :active="isLoading || !!load"
        :opacity="isFull ? 1 : 0.5"
    ></loading>
  </div>
</template>

<script>
import Loading from "vue-loading-overlay";
import EventBus from "@appfrm/core/utils/event-bus";

export default {
  components: {
    Loading
  },
  data() {
    return {
      loaders: 0,
      totalCount: null,
    };
  },
  props: {
    load: {
      type: Boolean,
      default: false
    },
    isFull: {
      type: Boolean,
      default: false
    },
  },
  created() {
    EventBus.$on("LOADING", () => this.loaders++);
    EventBus.$on("DONE-LOADING", () => this.loaders--);
  },
  computed: {
    isLoading: function () {
      return this.loaders > 0;
    },
  },
}
</script>

<style lang="scss" scoped>
@import "src/scss/variables";

::v-deep .vld-background {
  background: $light-color;
}
::v-deep .vld-icon {
  svg {
    stroke: $dark-color;
  }
}
</style>
