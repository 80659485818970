<template>
    <div class="result d-flex-1 flex-column m-10">
        <div class="d-flex-1 flex-column">

        </div>
    </div>
</template>

<script>

export default {
    name: 'HomePage',
    components: {  },
    props: {

    },
    data() {
        return {

        };
    },
    computed: {

    },
    async created() {

    },
    async mounted() {

    },
    methods: {

    }
}
</script>

<style scoped>

</style>